import React from "react"



const Publication = (props) => (
    <p className="text-sm padding-b">
        Article publication date: {props.Publication}
    </p>
)

export default Publication