import React from "react"



const UpdateDay = (props) => (
    <p className="text-sm ">
        Article update date: {props.update}
    </p>
)

export default UpdateDay